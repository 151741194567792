import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Define translations
const resources = {
  en: {
    translation: {
      home: "Home",
      about: "About",
      testimonials: "Testimonials",
      contact: "Contact",
      dashboard: "Dashboard",
      login: "Login",
      language: "Language",
    },
  },
  hy: {
    translation: {
      home: "Գլխավոր",
      about: "Մեր Մասին",
      testimonials: "Կարծիքներ",
      contact: "Կապ",
      dashboard: "Վահանակ",
      login: "Մուտք",
      language: "Լեզու",
    },
  },
};

// Initialize i18n
i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en", // Fallback if translation is missing
  interpolation: {
    escapeValue: false, // React already protects from XSS
  },
});

export default i18n;
